import { Box, Typography } from "@mui/material";

export const MatchingResults = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography>96%</Typography>
      <Typography>~ 25&deg;C</Typography>
    </Box>
  );
};
