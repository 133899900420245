import { Box } from "@mui/material";

export const VideoBackground = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: -1,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        "&::after": {
          content: "''",
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          display: "block",
          backgroundColor: "rgba(0,0,0,0.35)",
        },
      }}
    >
      <video
        muted
        autoPlay
        loop
        style={{
          maxHeight: "100vh",
        }}
        src={"./assets/advertisement.mp4"}
      />
    </Box>
  );
};
