import { useCallback, useContext, useEffect, useState } from "react";
// import axios from "axios";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { PreferenceContext } from "../../providers/preferences";

interface OwnProps {
  onSuccess: any;
  onExit(): any;
}

export const GPSLocator: React.FC<OwnProps> = ({ onSuccess, onExit }) => {
  const [loading, setLoading] = useState(false);
  const [location] = useState("");

  const prefs: any = useContext(PreferenceContext);

  const {
    gps: { gpsOrigin, setGPSOrigin },
  } = prefs;

  const [temp] = useState<null | number>(null);

  // const getCity = useCallback(
  //   (coords: any) => {
  //     const uri =
  //       " https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
  //       coords.latitude +
  //       "," +
  //       coords.longitude +
  //       "&sensor=true&key=" +
  //       process.env.REACT_APP_GOOGLE_APIKEY;
  //     // axios call
  //     axios.get(uri).then((data) => {
  //       const city = data?.data?.results[0]?.address_components[4].long_name;

  //       setLocation(city);
  //       setLoading(false);
  //       onSuccess(coords, city);
  //     });
  //   },
  //   [onSuccess]
  // );

  const getLocation = useCallback(() => {
    if (!gpsOrigin) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        // getCity(position.coords);
        setGPSOrigin({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        // // get temp
        // axios
        //   .get(
        //     "https://api.open-meteo.com/v1/forecast?latitude=" +
        //       position.coords.latitude +
        //       "&longitude=" +
        //       position.coords.longitude +
        //       "&current=temperature_2m"
        //   )
        //   .then((res: any) => {
        //     // console.log(res);
        //     setTemp(res.data.current.temperature_2m);
        //   });
      });
    }
  }, [setGPSOrigin, gpsOrigin]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return (
    <Box
      sx={{
        marginTop: 4,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "none",
      }}
    >
      <Typography variant="h5">{loading ? <></> : location}</Typography>
      <Typography>
        {!loading && <>Today {moment().format("HH:mm")}</>}
      </Typography>
      <Typography>{temp !== null && !loading && temp + " °C"} </Typography>
    </Box>
  );
};
