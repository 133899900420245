import { Box, Paper, Typography } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

interface ForecastItemProps {
  data: any;
}

export const ForecastItem: React.FC<ForecastItemProps> = ({ data }) => {
  return (
    <Paper
      sx={{
        padding: 1,
        display: "flex",
        marginRight: 1,
        justifyContent: "center",
        minWidth: 70,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
        }}
      >
        <b>{data.dayName}</b> {data.date}
        <hr style={{ width: "100%" }} />
        <Typography variant="body2">{data.temp_high} °C</Typography>
        <Typography variant="body2">{data.temp_low} °C</Typography>
        <hr style={{ width: "100%" }} />
        <Box
          display={"flex"}
          sx={{ flexDirection: " column", alignItems: "center" }}
        >
          <WbSunnyIcon />
          {data.condition_matching_percentage}%
        </Box>
      </Box>
    </Paper>
  );
};
