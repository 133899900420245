import { Outlet } from "react-router-dom";
// import { LanguageSelector } from "./components/preferences/ui/language-selector";

export const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
