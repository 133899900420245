import { Box, Typography } from "@mui/material";

export const LocationSummary = ({ description }: any) => {
  return description !== "undefined" ? (
    <Box
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Typography sx={{ color: "#fff" }}>{description}</Typography>
    </Box>
  ) : (
    <Box sx={{ height: 20 }}></Box>
  );
};
