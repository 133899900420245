import { Box, styled } from "@mui/material";
import { Logo } from "./logo";
import { TemperatureScale } from "../preferences/ui/temperature-scale";
import { StarCounter } from "../preferences/star-counter";
import { WeatherConditionsPreferences } from "../preferences/weather/weather-conditions-preferences";
import { DistancePreferences } from "../preferences/distance";
// import { LanguageSelector } from "../preferences/ui/language-selector";
import { DateRangePreferences } from "../preferences/date-range";

const PageHeaderWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#082f56",
  // borderBottom: "1px solid #ccc",

  marginBottom: theme.spacing(2),
}));

export const PageHeader = () => {
  return (
    <PageHeaderWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 0,
          padding: 2,
        }}
      >
        <Logo size="small" />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TemperatureScale />
          <StarCounter count={2} uri="/my-trip" />
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          // backgroundColor: "rgba(244,244,244,0.2)",
          padding: 2,
        }}
      >
        <WeatherConditionsPreferences />
        <DateRangePreferences />
        <DistancePreferences />
        {/* <LanguageSelector
          sx={{ height: "36px", right: 0, position: "absolute" }}
        /> */}
      </Box>
    </PageHeaderWrapper>
  );
};
