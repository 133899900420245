import { Box, Container, Typography } from "@mui/material";
import { LocationMap } from "../components/details/location-map";
import { PageHeader } from "../components/layout/page-header";
import { ForecastSummary } from "../components/details/forecast/forecast-summary";
import { BackButton } from "../components/routing/back-btn";
import { FavoriteButton } from "../components/location/partials/favorite-btn";
import { ForecastDayToDay } from "../components/details/forecast/forecast-day-to-day";
import { LocationSummary } from "../components/details/location-summary";
import { LocationContext } from "../providers/location";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageLocationDetails = () => {
  const { location, fetchLocation } = useContext(LocationContext);

  const loc = useLocation();

  useEffect(() => {
    const item = loc.pathname.split("/");
    const last = item[item.length - 1];
    // console.log(last);
    fetchLocation(last);
  }, [loc, fetch]);

  return (
    <>
      <PageHeader />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <BackButton />

          <Box>
            <Typography variant="h5">{location.name}</Typography>
            <Typography>
              {location.country_code} {location.region}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="body2"
              sx={{ marginBottom: -0.5, color: "#fff" }}
            >
              628 km,{" "}
            </Typography>
            <Typography variant="body2" sx={{ color: "#fff" }}>
              and surroundings
            </Typography>
          </Box>

          <FavoriteButton active={true} />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h5" paragraph>
            Forecast
          </Typography>
          <ForecastSummary />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h5" paragraph>
            Forecast - day to day
          </Typography>
          <ForecastDayToDay />
        </Box>
        <LocationMap location={location} />

        <LocationSummary description={location.description} />
      </Container>
    </>
  );
};
