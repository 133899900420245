import { Box, Paper, Typography } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

export const ForecastSummary = () => {
  return (
    <Paper
      sx={{
        padding: 2,
        backgroundColor: "primary.main",
        color: "#fff",
        marginBottom: 4,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">92%</Typography>
            <WbSunnyIcon style={{ fontSize: "2em", marginLeft: 2 }} />
          </Box>

          <Typography>15 jan - 23 jan</Typography>
          <Typography>(9 days)</Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography>27°C </Typography>
          <Typography variant="body2">avg max day temp.</Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography>16°C </Typography>
          <Typography variant="body2">avg night temp.</Typography>
        </Box>
      </Box>
    </Paper>
  );
};
