import { Box, Button, Container, Typography } from "@mui/material";
import { Logo } from "../components/layout/logo";
import "../i18n/config";
import { Link } from "react-router-dom";
import { Advertisement } from "../components/layout/advertisement";
import { useTranslation } from "react-i18next";
// import { LanguageSelector } from "../components/preferences/ui/language-selector";
import { VideoBackground } from "../components/layout/video-background";

export const Homepage = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        position: "relative",
        zIndex: 2,
      }}
    >
      {/* <LanguageSelector sx={{ top: -100 }} /> */}
      <Logo subtitle={t("subtitle")} />

      <Box sx={{ marginTop: 4, display: "flex", flexDirection: "column" }}>
        <Link to="/my-trip" style={{ textDecoration: "none", color: "#fff" }}>
          <Typography>{t("my_trip")}</Typography>
        </Link>

        <Button
          color="secondary"
          component={Link}
          to="/nice-weather-destinations"
          variant="contained"
        >
          {t("find_nice_weather_btn")}
        </Button>
        <VideoBackground />
      </Box>

      <Advertisement active={false} />
    </Container>
  );
};
