import { Switch, styled } from "@mui/material";
import { useContext } from "react";
import { PreferenceContext } from "../../../providers/preferences";

const TemperatureScaleSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  marginRight: 2,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        content: "'°F'",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
    width: 32,
    height: 32,
    "&::before": {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: "6px",
      top: "4px",
      content: "'°C'",
      fontWeight: "bold",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export const TemperatureScale = () => {
  const prefs: any = useContext(PreferenceContext);

  const {
    temp: { temperatureScale, setTemperatureScale },
  } = prefs;

  return (
    <>
      <TemperatureScaleSwitch
        checked={temperatureScale === "fahrenheit"}
        name="temp-scale-pref"
        onChange={() =>
          setTemperatureScale(
            temperatureScale === "fahrenheit" ? "celsius" : "fahrenheit"
          )
        }
      />
    </>
  );
};
