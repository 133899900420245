import { Alert, Box, Button, Container, Typography } from "@mui/material";
import { PageHeader } from "../components/layout/page-header";
import { LocationCard } from "../components/location/location-card";
import { useTranslation } from "react-i18next";
import { PreferenceContext } from "../providers/preferences";
import { useContext, useEffect } from "react";

export const PageNiceWeatherDestinations = () => {
  const { t } = useTranslation();
  const prefs: any = useContext(PreferenceContext);

  const { suggestions, fetchSuggestions } = prefs;

  useEffect(() => {
    // console.log(prefs);
  }, [suggestions]);

  return (
    <>
      <PageHeader />

      <Container>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" paragraph>
            Nice weather destinations
          </Typography>
        </Box>

        {suggestions &&
          suggestions.map((sug: any) => {
            return (
              <LocationCard
                key={sug.randomKey}
                city={sug.name}
                distance={sug.distance}
                region={sug.country_name + " " + sug.state_name}
                slug={sug.slug}
              />
            );
          })}

        <Alert sx={{ marginBottom: 4 }} severity="info">
          Disclaimer: Pellentesque habitant morbi
        </Alert>

        <Button
          variant="contained"
          color="secondary"
          sx={{ display: "flex", width: "100%", marginBottom: 4 }}
          onClick={() => fetchSuggestions()}
        >
          {t("refresh")}?
        </Button>
      </Container>
    </>
  );
};
