import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreferenceContext } from "../../providers/preferences";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

export const DateRangePreferences = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const prefs: any = useContext(PreferenceContext);

  const {
    dateRange: { dateRange, setDateRange },
  } = prefs;

  const { date_from, date_till, date_label } = dateRange;

  const [datePreference, setDatePreference] = useState({
    date_from,
    date_till,
    date_label,
  });

  return (
    <>
      {/* Interaction button */}
      <Button
        variant="text"
        onClick={() => setOpen(!open)}
        sx={{
          height: "50px",
          backgroundColor: "rgba(244,244,244,0.15)",
          padding: "30px 5px",
          marginLeft: 1,
        }}
      >
        <Typography variant="body2">
          {datePreference.date_from.format("DD MMM")} -{" "}
          {datePreference.date_till.format("DD MMM")} <br />
          <span style={{ fontSize: ".8em" }}>
            {" "}
            ({datePreference.date_label})
          </span>
        </Typography>
      </Button>

      {/* Dialog for setting weather preferences */}
      <Dialog
        maxWidth="xl"
        sx={{ width: "100%" }}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <Typography variant="h5" color={"primary"} paragraph>
            {t("when")}?
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateCalendar
                onChange={(date) => {
                  setDatePreference({
                    date_from: moment(date),
                    date_till: moment(date).add(6, "days"),
                    date_label: "a week",
                  });
                }}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            paddingBottom: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDateRange(datePreference);
              setOpen(false);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
