import { Box } from "@mui/material";
import { ForecastItem } from "./forecast-item";

export const ForecastDayToDay: any = () => {
  const forecast = [
    {
      dayName: "MON",
      date: "3 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
    {
      dayName: "TUE",
      date: "4 May",
      temp_high: 25,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
    {
      dayName: "WED",
      date: "5 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
    {
      dayName: "THU",
      date: "6 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 70,
    },
    {
      dayName: "FRI",
      date: "7 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
    {
      dayName: "SAT",
      date: "8 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
    {
      dayName: "SUN",
      date: "9 May",
      temp_high: 23,
      temp_low: 11,
      condition: "sun",
      condition_matching_percentage: 100,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: 4,
        overflowX: "scroll",
        maxWidth: "100%",
        width: "100%",
        paddingBottom: 2,
      }}
    >
      {forecast.map((data) => {
        return <ForecastItem key={data.dayName} data={data} />;
      })}
    </Box>
  );
};
