import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface StarCounterProps {
  count: number;
  uri: string;
}

export const StarCounter: React.FC<StarCounterProps> = ({ count, uri }) => {
  return (
    <Link
      to={uri}
      style={{
        display: "flex",
        flexDirection: "row",
        textDecoration: "none",
        color: "#fff",
        fontSize: "1.1em",
      }}
    >
      <FavoriteIcon />
      <Chip label={count} size="small" sx={{ color: "inherit" }} />
    </Link>
  );
};
