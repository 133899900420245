import { Box, Button, Typography } from "@mui/material";

export const PageBranding = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Box>
        <Typography variant="h1">Heading1</Typography>
        <Typography variant="h2">Heading2</Typography>
        <Typography variant="h3">Heading3</Typography>
        <Typography variant="h4">Heading4</Typography>
        <Typography variant="h5">Heading5</Typography>
        <Typography variant="h6">Heading6</Typography>
        <Typography>
          Curabitur nisl quam, dignissim in sodales sed, maximus et orci. Mauris
          nisl nunc, sollicitudin quis massa vitae, viverra egestas lacus.
          Praesent consectetur varius dui vel fermentum. Praesent ut elementum
          nisi. Pellentesque at nulla quis mi tempus ultricies. Sed magna sem,
          molestie sit amet dapibus gravida, mollis ac erat. Ut ultrices, quam
          vitae elementum luctus, turpis dui molestie ligula, eget rutrum dui
          ipsum et lorem. Vivamus quis venenatis arcu.
        </Typography>
      </Box>

      <Box>
        <Button variant="contained" color="primary">
          primary
        </Button>
        <Button variant="contained" color="secondary">
          secondary
        </Button>
      </Box>
      <Box>
        <Button variant="outlined" color="primary">
          primary
        </Button>
        <Button variant="outlined" color="secondary">
          secondary
        </Button>
      </Box>
    </Box>
  );
};
