import { Box, Paper } from "@mui/material";
import { MatchingResults } from "./partials/matching-results";
import { CityDisplay } from "./partials/city-display";
import { FavoriteButton } from "./partials/favorite-btn";
import { useNavigate } from "react-router-dom";

interface LocationCardProps {
  city: string;
  region: string;
  distance: any;
  active?: boolean;
  slug: string;
}

export const LocationCard: React.FC<LocationCardProps> = ({
  active,
  region,
  city,
  distance,
  slug,
}) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 4,
        cursor: "pointer",
      }}
      onClick={() => navigate("/location-details/" + slug)}
    >
      <MatchingResults />
      <CityDisplay city={city} region={region} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "100px",
          alignItems: "flex-end",
        }}
      >
        <FavoriteButton active={active} />
        <>{distance} km</>
      </Box>
    </Paper>
  );
};
