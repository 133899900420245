import { Box, Button, Container, Typography } from "@mui/material";
import { PageHeader } from "../components/layout/page-header";
import { LocationCard } from "../components/location/location-card";

import { BackButton } from "../components/routing/back-btn";

export const PageMyTrip = () => {
  return (
    <>
      <PageHeader />

      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "",
            marginBottom: 2,
          }}
        >
          <BackButton />
          <Typography variant="h5">My trip</Typography>
        </Box>

        <LocationCard
          city="Saint-Malo"
          region="France, region x"
          active={true}
          distance={10}
          slug={"saint-malo"}
        />

        <LocationCard
          city="Renesse"
          region="Netherlands, Zeeland"
          active={true}
          distance={10}
          slug={"renesse"}
        />

        <Button
          size="small"
          variant="contained"
          sx={{ float: "right", margin: 0 }}
        >
          Clear
        </Button>
      </Container>
    </>
  );
};
