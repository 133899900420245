import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./layout";
import { Homepage } from "./pages/home";
import { PageBranding } from "./pages/branding";
import { PageNotFound } from "./pages/notfound";
import { PageNiceWeatherDestinations } from "./pages/nice-weather-destinations";
import { PageLocationDetails } from "./pages/location-details";
import { PageMyTrip } from "./pages/my-trip";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route
            path="nice-weather-destinations"
            element={<PageNiceWeatherDestinations />}
          />
          <Route path="my-trip" element={<PageMyTrip />} />
          <Route path="branding" element={<PageBranding />} />

          <Route
            path="location-details/:locationSlug"
            element={<PageLocationDetails />}
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
