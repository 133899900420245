import { Snackbar } from "@mui/material";

interface AdvertisementProps {
  active?: boolean;
}

export const Advertisement: React.FC<AdvertisementProps> = ({ active }) => {
  return (
    <Snackbar
      open={active}
      autoHideDuration={120}
      onClose={() => {}}
      message="Check out the new release"
    />
  );
};
