import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export const LocationMap = ({ active, location }: any) => {
  const [zoom, setZoom] = useState(7);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APIKEY || "",
  });

  useEffect(() => {
    setZoom(22);
  }, [isLoaded]);

  // console.log(map);
  const onLoad = React.useCallback(
    function callback(map: any) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      });
      // map.fitBounds(bounds);
      console.log(map);
    },
    [location]
  );

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: 300 }}
      center={{
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      }}
      zoom={12}
      onLoad={onLoad}
      mapTypeId="satellite"
      options={{
        disableDefaultUI: true,
      }}
    >
      <Marker
        position={{
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};
