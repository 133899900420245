import moment from "moment";

const getNextMonday = () => {
  const dayINeed = 1; // for Thursday
  const today = moment().isoWeekday();

  if (today < dayINeed) {
    return moment().isoWeekday(dayINeed);
  } else {
    return moment().add(1, "weeks").isoWeekday(dayINeed);
  }
};

export const dateRangeConfig = {
  date_from: getNextMonday(),
  date_till: getNextMonday().add(6, "days"),
  date_label: "Next week",
};
