import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0575E6",
    },
    secondary: {
      main: "#F7CC1E",
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontFamily: "sf_compact_roundedblack",
      fontWeight: 800,
      margin: "0 10px 10px 0",
      color: "#fff",
    },
    h2: {
      color: "#fff",
    },
    h3: {
      color: "#fff",
    },
    h4: {
      color: "#fff",
    },
    h5: {
      color: "#fff",
    },
    body1: {
      margin: "0 10px 10px 0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          margin: "0 10px 10px 0",
        },
        text: {
          color: "#fff",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      },
    },
    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
        },
      },
    },
  },
});
