import { Box, Typography } from "@mui/material";

interface CityDisplayProps {
  city: string;
  region: string;
}

export const CityDisplay: React.FC<CityDisplayProps> = ({ city, region }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        marginLeft: 2,
      }}
    >
      <Typography variant="h6" color="primary">
        {city}
      </Typography>
      {/* <Typography>{region}</Typography> */}
    </Box>
  );
};
