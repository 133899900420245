import { Box, Typography, keyframes, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Logo = ({ children, subtitle, size }: any) => {
  const animation = keyframes`
      0% {
        background-position: 0% 50%;
        background-clip: text;
      }
      50% {
        background-position: 100% 75%;
        background-clip: text;
      }

      100% {
    	background-position: 0% 50%;
        background-clip: text;
      }
    `;

  const LogoWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  }));

  const MovingLogo = styled(Typography)(({ theme }) => ({
    animation: `${animation} 6s ease infinite`,
    background: "#fff",
    backgroundSize: "200% 200%",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    marginBottom: 0,
    transition: "all .3s",
  }));

  return (
    <Box
      component={Link}
      to="/nice-weather-destinations"
      sx={{ textDecoration: "none" }}
    >
      <LogoWrapper>
        <MovingLogo variant="h1" color={"secondary"} style={{}}>
          {size === "small" ? (
            <Box sx={{ fontSize: ".5em" }}>Wedr.</Box>
          ) : (
            <>Wedr.</>
          )}
        </MovingLogo>
        {subtitle && (
          <Typography variant="h4" color="secondary" sx={{ color: "#fff" }}>
            {subtitle}
          </Typography>
        )}
      </LogoWrapper>
    </Box>
  );
};
