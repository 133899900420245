import Sunny from "../weather-icons/sunset.svg";
import Cloudy from "../weather-icons/partly-cloudy-day-drizzle.svg";
import SomeRain from "../weather-icons/partly-cloudy-day-rain.svg";

const SunnyIcon = () => {
  return <img src={Sunny} alt="sunny" width="100%" height="100px" />;
};

const CloudyICon = () => {
  return <img src={Cloudy} alt="partly cloudy" width="100%" height="100px" />;
};

const SomeRainIcon = () => {
  return (
    <img
      src={SomeRain}
      alt="partly cloudy some rain"
      width="100%"
      height="100px"
    />
  );
};

export const weatherPreferenceOptions = [
  {
    name: "Sunny",
    value: "sun",
    description: "Sunny!",
    icon: SunnyIcon,
  },
  {
    name: "Sun and some clouds",
    value: "sun_clouds",
    description: "Sun and some clouds are perfectly fine!",
    icon: CloudyICon,
  },
  {
    name: "Sun and some clouds and a few drops",
    value: "sun_clouds_rain",
    description:
      "Sun and cloudy is fine a few drops of rain from time to time is acceptable",
    icon: SomeRainIcon,
  },
];
