import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translation_en from "./en/translation.json";
import translation_nl from "./nl/translation.json";

i18next.use(initReactI18next).init({
  lng: "en",
  debug: false,
  resources: {
    en: {
      translation: translation_en,
    },
    nl: {
      translation: translation_nl,
    },
  },
});
