import { createContext, useEffect, useState } from "react";
import { weatherPreferenceOptions } from "../data/weather-preferences";
import { distanceConfig } from "../data/distance";
import { dateRangeConfig } from "../data/date-range";
import axios from "axios";
import moment from "moment";
import { useLocalStorage } from "usehooks-ts";

interface PreferenceProviderProps {
  children: any;
}

interface PreferencesProps {
  weather?: any;
  temp?: any;
}

export const PreferenceContext = createContext<PreferencesProps>({});

export const PreferenceProvider: React.FC<PreferenceProviderProps> = ({
  children,
}) => {
  // weather
  const [weatherPreference, setWeatherPreference] = useState(
    weatherPreferenceOptions[0]
  );

  // GPS origin
  const [gpsOrigin, setGPSOrigin] = useLocalStorage("gps_origin", null);

  // date range defaults
  const { date_from, date_till, date_label } = dateRangeConfig;

  const { defaultDistance } = distanceConfig;

  // temp scale
  const [temperatureScale, setTemperatureScale] = useLocalStorage(
    "temperature_scale",
    "celcius"
  );

  // distance
  const [distancePreference, setDistancePreference] = useLocalStorage(
    "distance",
    defaultDistance
  );

  const [suggestions, setSuggestions] = useState([]);

  // dateRange
  const [dateRange, setDateRange] = useState({
    date_from,
    date_till,
    date_label,
  });

  const getParams = () => {
    return {
      limit: 3,
      gps: gpsOrigin,
      distance: distancePreference,
      weatherPreference: weatherPreference.value,
      datesRange: {
        from: moment(dateRange.date_from).format("DD-MM-yyyy"),
        till: moment(dateRange.date_till).format("DD-MM-yyyy"),
      },
    };
  };

  const fetchSuggestions = async () => {
    const params = getParams();
    await axios
      .get(process.env.REACT_APP_BACKEND_SERVER + "/suggestions", { params })
      .then((res: any) => {
        setSuggestions(res.data.suggestions);
      });
  };

  useEffect(() => {
    // call backend for suggestions
    if (gpsOrigin) {
      fetchSuggestions();
    }
  }, [distancePreference, weatherPreference, dateRange, gpsOrigin]);

  const settings = {
    weather: {
      weatherPreference,
      setWeatherPreference,
      weatherPreferenceOptions,
    },
    gps: { gpsOrigin, setGPSOrigin },
    temp: { temperatureScale, setTemperatureScale },
    distance: { distancePreference, setDistancePreference },
    dateRange: { dateRange, setDateRange },
    suggestions,
    fetchSuggestions,
  };

  return (
    <PreferenceContext.Provider value={settings}>
      {children}
    </PreferenceContext.Provider>
  );
};
