import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

interface PreferenceProviderProps {
  children: any;
}

interface LocationProps {
  location?: any;
  fetchLocation?: any;
}

export const LocationContext = createContext<LocationProps>({});

export const LocationProvider: React.FC<PreferenceProviderProps> = ({
  children,
}) => {
  const [location, setLocation] = useState<any>({});

  const [fetched, setFetched] = useState(false);

  const fetchLocation = async (slug: any) => {
    await axios
      .get(process.env.REACT_APP_BACKEND_SERVER + "/location?slug=" + slug, {})
      .then((res: any) => {
        setLocation(res.data);
        setFetched(true);
      });
  };

  const settings = {
    location,
    fetchLocation,
  };

  return (
    <LocationContext.Provider value={settings}>
      {children}
    </LocationContext.Provider>
  );
};
