import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreferenceContext } from "../../providers/preferences";
import { distanceConfig } from "../../data/distance";

export const DistancePreferences = () => {
  const [open, setOpen] = useState(false);
  const { defaultDistance, distanceMax, distanceMin, distanceUnit } =
    distanceConfig;

  const { t } = useTranslation();

  const prefs: any = useContext(PreferenceContext);

  const {
    distance: { distancePreference, setDistancePreference },
  } = prefs;

  const [distance, setDistance] = useState(
    distancePreference || defaultDistance
  );

  return (
    <>
      {/* Interaction button */}
      <Button
        variant="text"
        onClick={() => setOpen(!open)}
        sx={{
          height: "50px",
          backgroundColor: "rgba(244,244,244,0.15)",
          padding: "30px 5px",
          marginLeft: 1,
        }}
      >
        <Typography variant="body2">
          {distancePreference} {distanceUnit}
        </Typography>
      </Button>

      {/* Dialog for setting weather preferences */}
      <Dialog
        maxWidth="xl"
        sx={{ width: "100%" }}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <Typography variant="h5" color={"primary"} paragraph>
            {t("distance")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 10px",
            }}
          >
            <Slider
              min={distanceMin}
              max={distanceMax}
              step={10}
              value={distance}
              sx={{
                "&::before": {
                  content: "''",
                  position: "absolute",
                  top: 6,
                  left: -1,
                  right: -1,
                  height: 30,
                  width: "100%",
                  borderLeft: "2px solid rgba(5,117,235,0.38)",
                  borderRight: "2px solid rgba(5,117,235,0.38)",
                },
              }}
              marks={[
                {
                  label: "0",
                  value: 0,
                },
                {
                  label: distanceMax,
                  value: distanceMax,
                },
              ]}
              onChange={(e, newValue: any) => {
                setDistance(newValue);
              }}
            />
            <Typography>
              {distance} {distanceUnit}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            paddingBottom: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDistancePreference(distance);
              setOpen(false);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
