import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreferenceContext } from "../../../providers/preferences";

export const WeatherConditionsPreferences = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const prefs: any = useContext(PreferenceContext);

  const {
    weather: {
      weatherPreference,
      setWeatherPreference,
      weatherPreferenceOptions,
    },
  } = prefs;

  const [weatherItem, setWeatherItem] = useState(weatherPreference);

  return (
    <>
      {/* Interaction button */}
      <Button
        variant="text"
        onClick={() => setOpen(!open)}
        sx={{
          height: "50px",
          backgroundColor: "rgba(244,244,244,0.15)",
          padding: "30px 5px",
        }}
      >
        {<weatherPreference.icon />}
      </Button>

      {/* Dialog for setting weather preferences */}
      <Dialog
        maxWidth="xl"
        sx={{ width: "100%" }}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <Typography variant="h5" color={"primary"} paragraph>
            {t("favorite_weather")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {weatherPreferenceOptions.map((pref: any) => {
              const active = pref.value === weatherItem.value;
              return (
                <Box
                  key={pref.value}
                  sx={{
                    flexDirection: "column",
                    padding: 2,
                    borderRadius: 4,
                    marginBottom: 2,
                    border: active ? "2px solid" : "none",
                    borderColor: active ? "primary.main" : "transparent",
                    color: active ? "#fff" : "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => setWeatherItem(pref)}
                >
                  <pref.icon sx={{ fontSize: "3.3em" }} />
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
              minHeight: 85,
            }}
          >
            <Typography>{weatherItem.description}</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            paddingBottom: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setWeatherPreference(weatherItem);
              setOpen(false);
            }}
          >
            Continue
          </Button>
          <Typography variant="body2">Want to specify temperature?</Typography>
        </DialogActions>
      </Dialog>
    </>
  );
};
