import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme/wedr";
import { GPSLocator } from "./components/gps/gps-locator";

import { PreferenceProvider } from "./providers/preferences";
import { LocationProvider } from "./providers/location";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PreferenceProvider>
      <LocationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GPSLocator onSuccess={(e: any) => {}} onExit={() => {}} />
          <App />
        </ThemeProvider>
      </LocationProvider>
    </PreferenceProvider>
  </React.StrictMode>
);
